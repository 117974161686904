@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e1e1e;
  padding: 0 20px;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 2;
}

nav a {
  color: #bb86fc;
  margin: 0 15px;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

.logo a {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #e0e0e0;
  text-decoration: none;
}

.nav-links {
  list-style: none;
  display: flex;
  z-index: 1;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  font-family: "Roboto", sans-serif;
  color: #e0e0e0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links .active {
  font-weight: bold;
  color: #bb86fc;
}

.nav-links a:hover,
.logo a:hover {
  color: #bb86fc;
}

.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: #e0e0e0;
  margin: 5px;
  transition: all 0.3s ease;
}

.nav-active {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #1e1e1e;
}

.line1-active {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.line2-active {
  opacity: 0;
}

.line3-active {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .burger {
    display: block;
  }

  .nav-links.nav-active {
    display: flex;
  }

  .nav-links li {
    margin: 15px 0;
  }
}

.border-divider {
  border-color: #282828;
}

.error {
  color: #cf6679;
}
