* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #121212;
  color: #e0e0e0;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #e0e0e0;
  margin-bottom: 0.5em;
}

p {
  color: #b0b0b0;
  margin-bottom: 1em;
}

a {
  color: #bb86fc;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #03dac6;
}

button {
  background-color: #bb86fc;
  color: #e0e0e0;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #bb86fc;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.card {
  background-color: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
}

.card img {
  width: 100%;
  display: block;
}

.card-content {
  padding: 20px;
}

.card-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #e0e0e0;
}

.card-description {
  color: #b0b0b0;
}

/* Forms */
form {
  display: flex;
  flex-direction: column;
}

input,
textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  background-color: #1e1e1e;
  color: #e0e0e0;
}

input::placeholder,
textarea::placeholder {
  color: #b0b0b0;
}

input:focus,
textarea:focus {
  outline: none;
  border: 1px solid #bb86fc;
}

/* Footer */
.footer {
  background-color: #1e1e1e;
  color: #b0b0b0;
  text-align: center;
  padding: 20px;
}

.footer a {
  color: #bb86fc;
}

.footer a:hover {
  color: #03dac6;
}

.text-center {
  text-align: center;
}

.mb-1 {
  margin-bottom: 1em;
}

.mt-1 {
  margin-top: 1em;
}

.pb-1 {
  padding-bottom: 1em;
}

.pt-1 {
  padding-top: 1em;
}

.px-2 {
  padding-left: 2em;
  padding-right: 2em;
}

.m-1 {
  margin: 1em;
}

.p-1 {
  padding: 1em;
}

.rounded {
  border-radius: 8px;
}

.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #1e1e1e;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #bb86fc;
  border-radius: 8px;
  border: 3px solid #1e1e1e;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #03dac6;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #bb86fc #1e1e1e;
}

::-ms-scrollbar {
  width: 12px;
}

::-ms-scrollbar-track {
  background-color: #1e1e1e;
  border-radius: 8px;
}

::-ms-scrollbar-thumb {
  background-color: #bb86fc;
  border-radius: 8px;
  border: 3px solid #1e1e1e;
}

::-ms-scrollbar-thumb:hover {
  background-color: #03dac6;
}
